import { useEffect } from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { zodResolver } from '@hookform/resolvers/zod';
import posthog from 'posthog-js';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { useNavigate } from 'react-router-dom';
import { Button } from '@/components/ui/button';
import { Form, FormControl, FormField, FormItem, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import useAccountUsers from '@/hooks/useAccountUsers';
import { NewPatientFormValues, newPatientSchema } from '@/schema/newPatientSchema';
import { IAccountPatient } from '@/types';
import axiosClient from '@/utils/axiosClient';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../ui/select';

interface PatientFormProps {
  patient?: IAccountPatient;
}

const PatientForm: React.FC<PatientFormProps> = ({ patient }) => {
  const modal = useModal('new-patient-drawer');
  const navigate = useNavigate();
  const accountStaff = useAccountUsers();

  const form = useForm<NewPatientFormValues>({
    resolver: zodResolver(newPatientSchema),
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      dob: '',
      phone: '',
      address: '',
      accountUserId: '',
    },
  });

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors, isSubmitting },
  } = form;

  useEffect(() => {
    if (patient && patient.user) {
      console.log('patient:', patient);
      console.log('accountStaff:', accountStaff);
      setValue('firstName', patient.user.first_name || '');
      setValue('lastName', patient.user.last_name || '');
      setValue('email', patient.user.email || '');
      setValue('phone', patient.user.phone || '');
      setValue('accountUserId', String(patient.account_user_id));
      setValue('address', patient?.requests[0]?.location?.address || '');

      const dob = patient.user.dob ? new Date(patient.user.dob) : null;
      if (dob) {
        dob.setMinutes(dob.getMinutes() + dob.getTimezoneOffset());
        setValue('dob', dob.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
      } else {
        setValue('dob', '');
      }
    }
  }, [patient, setValue]);

  const onSubmit = async (data: NewPatientFormValues) => {
    try {
      const payload = {
        patient: {
          first_name: data.firstName,
          last_name: data.lastName,
          email: data.email,
          dob: data.dob,
          phone: data.phone.replace(/\D/g, ''),
          address: data.address,
          account_user_id: data.accountUserId,
        },
      };

      let response;
      if (patient) {
        response = await axiosClient.put(`/v2/patients/${patient.id}`, payload);
      } else {
        response = await axiosClient.post('/v2/patients', payload);
      }

      if (response.data && response.data.account_patient_id) {
        accountStaff.refetch();
        posthog.capture('provider_patient_intake');
        modal.hide();
        navigate(`/patients/${response.data.account_patient_id}`);
      } else {
        navigate('/patients');
      }
    } catch (error: any) {
      console.error('Error saving patient:', error);
      if (error.response?.data?.message) {
        alert(
          Array.isArray(error.response.data.message)
            ? error.response.data.message.join('\n')
            : error.response.data.message,
        );
      }
    }
  };

  const prescribers = accountStaff?.data?.filter((staff) => staff.is_prescriber) || [];
  console.log('prescribers:', prescribers);

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="flex flex-col items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
            {/* First Name and Last Name */}
            <div className="flex items-start gap-8 self-stretch w-full relative flex-[0_0_auto]">
              <FormField
                control={control}
                name="firstName"
                render={({ field }) => (
                  <div className="flex-1 grow">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">First Name</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          autoFocus
                          autoComplete="off"
                        />
                      </FormControl>
                      {errors.firstName && <FormMessage>{errors.firstName.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={control}
                name="lastName"
                render={({ field }) => (
                  <div className="flex-1 grow">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Last Name</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        />
                      </FormControl>
                      {errors.lastName && <FormMessage>{errors.lastName.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>

            {/* Email Address and Zip Code */}
            <div className="relative flex flex-row items-start self-stretch w-full gap-8">
              <FormField
                control={control}
                name="email"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Email Address</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          placeholder="Email Address"
                        />
                      </FormControl>
                      {errors.email && <FormMessage>{errors.email.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={control}
                name="address"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Zip Code</Label>
                      <FormControl>
                        <Input
                          {...field}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                          placeholder="Enter a address"
                        />
                      </FormControl>
                      {errors.address && <FormMessage>{errors.address.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>

            {/* Phone Number and Date of Birth */}
            <div className="relative flex flex-row items-start self-stretch w-full gap-8">
              <FormField
                control={control}
                name="phone"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Phone Number</Label>
                      <FormControl>
                        <InputMask
                          mask="(999) 999-9999"
                          {...field}
                          onChange={(e) => field.onChange(e.target.value)}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        >
                          {(inputProps: any) => <Input type="tel" {...inputProps} placeholder="(555) 555-5555" />}
                        </InputMask>
                      </FormControl>
                      {errors.phone && <FormMessage>{errors.phone.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
              <FormField
                control={control}
                name="dob"
                render={({ field }) => (
                  <div className="w-full">
                    <FormItem>
                      <Label className="block mb-2 text-sm font-medium text-gray-700">Date of Birth</Label>
                      <FormControl>
                        <InputMask
                          mask="99/99/9999"
                          {...field}
                          onChange={(e) => field.onChange(e.target.value)}
                          className="w-full p-3 pl-4 text-gray-700 bg-white border border-gray-300 rounded-full"
                        >
                          {(inputProps: any) => <Input {...inputProps} placeholder="MM/DD/YYYY" />}
                        </InputMask>
                      </FormControl>
                      {errors.dob && <FormMessage>{errors.dob.message}</FormMessage>}
                    </FormItem>
                  </div>
                )}
              />
            </div>

            {/* Assigned Prescriber */}
            <FormField
              control={control}
              name="accountUserId"
              render={({ field }) => (
                <div className="w-full">
                  <FormItem>
                    <Label className="block mb-2 text-sm font-medium text-gray-700">Assigned Prescriber</Label>
                    <FormControl>
                      <Select onValueChange={(value) => field.onChange(value)} value={field.value}>
                        <SelectTrigger>
                          <SelectValue placeholder="Assign patient to prescriber" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="">Assign patient to prescriber</SelectItem>
                          {prescribers.map((staff: any) => (
                            <SelectItem key={staff.id} value={String(staff.id)}>
                              {staff.user.name} ({staff.role})
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </FormControl>
                    {errors.accountUserId && <FormMessage>{errors.accountUserId.message}</FormMessage>}
                  </FormItem>
                </div>
              )}
            />

            {/* Submit Button */}
            <div className="inline-flex items-center justify-end gap-2 relative flex-[0_0_auto] cursor-pointer w-full mt-2">
              <Button type="submit" disabled={isSubmitting} className="w-full sm:w-fit">
                {isSubmitting
                  ? patient?.id
                    ? 'Updating patient...'
                    : 'Saving patient...'
                  : patient?.id
                    ? 'Update Patient'
                    : 'Create Patient'}
              </Button>
            </div>
          </div>
        </form>
      </Form>
    </div>
  );
};

export default PatientForm;
