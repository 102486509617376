import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Edit, Plus, Search } from 'lucide-react';
import {
  AlertDialog,
  AlertDialogAction,
  AlertDialogCancel,
  AlertDialogContent,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogTrigger,
} from '@/components/ui/alert-dialog';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import { useToast } from '@/hooks/useToast';
import { IAccountPatient, IMedication } from '@/types';
import axiosClient from '@/utils/axiosClient';
import MedIcon from '../MedIcon';
import { Button } from '../ui/button';
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from '../ui/table';

interface MedicationListProps {
  patient: IAccountPatient;
}

const MedicationList: React.FC<MedicationListProps> = ({ patient }) => {
  const medicationModal = useModal('medication-modal');
  const { toast } = useToast();
  const medications = patient?.medications || [];

  const handleEdit = (medication: IMedication) => {
    medicationModal.show({
      medication: medication,
      medications: medications,
      userId: patient.user.id,
    });
  };

  const handleAddMedication = () => {
    medicationModal.show({
      medications: [],
      accountId: patient.account_id,
      userId: patient.user.id,
    });
  };

  const handleStartOrder = async (medication: IMedication) => {
    try {
      const response = await axiosClient.post('/v1/requests/create_provider', {
        medication_id: medication.id,
        user_id: patient.user.id,
        fresh: true,
      });

      if (response.data && response.data.id) {
        toast({
          title: 'Request Created',
          description: patient.user.phone
            ? "A link has been sent to the patient's phone number."
            : 'Request created successfully.',
          duration: 5000,
        });
      }
    } catch (error) {
      console.error('Error creating new request:', error);
      toast({
        title: 'Error',
        description: 'Failed to create request. Please try again.',
        variant: 'destructive',
      });
    }
  };

  return (
    <>
      {' '}
      <Card>
        <CardHeader>
          <div className="flex items-center justify-between ">
            <CardTitle>Medications</CardTitle>
            <Button onClick={handleAddMedication}>
              <Plus className="w-5 h-5 mr-1" />
              Add Medication
            </Button>
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Medication</TableHead>
                <TableHead>Dosage</TableHead>
                <TableHead>Form</TableHead>
                <TableHead>Actions</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {medications.length > 0 ? (
                medications.map((medication: IMedication) => (
                  <TableRow key={medication.id}>
                    <TableCell>
                      <div className="flex items-center gap-2">
                        <MedIcon medication={medication} />
                        {medication.medication}
                      </div>
                    </TableCell>
                    <TableCell>{medication.dosage}</TableCell>
                    <TableCell>{medication.dosage_form}</TableCell>
                    <TableCell>
                      <div className="flex space-x-2">
                        <Button onClick={() => handleEdit(medication)} variant="outline" size="sm">
                          <Edit className="w-4 h-4 mr-1" />
                          Edit
                        </Button>

                        <AlertDialog>
                          <AlertDialogTrigger>
                            <Button size="sm">
                              <Search className="w-4 h-4 mr-1" />
                              Start Search
                            </Button>
                          </AlertDialogTrigger>
                          <AlertDialogContent>
                            <AlertDialogHeader>
                              <AlertDialogTitle>Are you ready to start?</AlertDialogTitle>
                              <AlertDialogDescription>
                                <div>
                                  Patients will be sent a link that will allow them to complete their profile and select
                                  their pharmacies.
                                </div>
                              </AlertDialogDescription>
                            </AlertDialogHeader>
                            <AlertDialogFooter>
                              <AlertDialogCancel>Cancel</AlertDialogCancel>
                              <AlertDialogAction onClick={() => handleStartOrder(medication)}>
                                Start Search
                              </AlertDialogAction>
                            </AlertDialogFooter>
                          </AlertDialogContent>
                        </AlertDialog>
                      </div>
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5} className="text-center">
                    No medications found.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
    </>
  );
};

export default MedicationList;
