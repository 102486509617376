import React from 'react';
import { useModal } from '@ebay/nice-modal-react';
import { Edit } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Card, CardContent, CardHeader, CardTitle } from '@/components/ui/card';
import useUser from '@/hooks/useUser';
import { IAccountPatient } from '@/types';

interface PatientDetailsProps {
  patient: IAccountPatient;
}

const PatientDetails: React.FC<PatientDetailsProps> = ({ patient }) => {
  const modal = useModal('new-patient-drawer');
  const user = useUser(patient.user.id as string);

  if (user.isLoading || !user.data) {
    return null;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between ">
          <CardTitle>{user.data.name ? user.data.name : user.data.email}</CardTitle>
          <Button
            onClick={() => {
              modal.show({ patient: patient });
            }}
            className="gap-2 btn btn-primary"
          >
            <Edit size={16} />
            Edit Patient
          </Button>
        </div>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
          {/* Personal Information */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Personal Information</h3>
            <div className="space-y-2">
              <p>
                <strong>Email:</strong> {user.data.email || 'N/A'}
              </p>
              <p>
                <strong>Phone:</strong> {user.data.phone || 'N/A'}
              </p>
              <p>
                <strong>Date of Birth:</strong> {user.data.dob || 'N/A'}
              </p>
            </div>
          </div>

          {/* Address Information */}
          <div>
            <h3 className="mb-4 text-xl font-semibold">Address Information</h3>
            {user.data.locations && user.data.locations.length > 0 ? (
              <div className="space-y-2">
                <p>
                  <strong>Address:</strong> {user.data.locations[0].address}
                </p>
                <p>
                  <strong>City:</strong> {user.data.locations[0].city}
                </p>
                <p>
                  <strong>State:</strong> {user.data.locations[0].state}
                </p>
              </div>
            ) : (
              <p>No address information available.</p>
            )}
          </div>
        </div>
      </CardContent>
    </Card>
  );
};

export default PatientDetails;
